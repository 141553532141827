import {
  CYCLOPS_LIVESTREAMS_DATA_LOADED,
  CYCLOPS_LIVESTREAMS_DATA_CLEAR,
  CYCLOPS_LIVESTREAM_DETAILS_LOADED,
  CYCLOPS_LIVESTREAM_DETAILS_CLEAR,
} from '../../actionTypes';

import * as api from '../../apiHelper';
import { initiateRedirectRequest } from '../../redirectActions';
import {
  setFetchLoaderLoading,
  setFetchLoaderReset,
  setSubmissionLoaderLoading,
  setSubmissionLoaderReset,
} from '../../fetchLoaderActions';
import { arriveNotice } from '../../noticeActions';
import { setFaviconLoading, setFaviconDefault } from '../../faviconActions';


// Reducer corresponding actions
function cyclopsLivestreamsDataClear() {
  return { type: CYCLOPS_LIVESTREAMS_DATA_CLEAR };
}

function cyclopsLivestreamsDataLoaded(livestreams) {
  return {
    type: CYCLOPS_LIVESTREAMS_DATA_LOADED,
    payload: { livestreams },
  };
}

function cyclopsLivestreamDetailsClear() {
  return { type: CYCLOPS_LIVESTREAM_DETAILS_CLEAR };
}

function cyclopsLivestreamDetailsLoaded(livestreamDetails) {
  return {
    type: CYCLOPS_LIVESTREAM_DETAILS_LOADED,
    payload: { livestreamDetails },
  };
}

// API
async function getCompanyLivestreams(args, token) {
  const response = await api.nestedParamsGet('cyclops/live_streams', args, token, 'v3');
  return response.json();
}

async function getLivestreamDetails(id, args, token) {
  const response = await api.get(`cyclops/live_streams/${id}`, args, token, 'v3');
  return response.json();
}

async function submitNewLivestreamApi(body, token) {
  const response = await api.post('cyclops/live_streams', body, token, 'v3');

  if (!response.ok) {
    const json = await response.json();
    throw Error(json.error);
  }

  return response.json();
}

async function editLivestreamApi(body, token) {
  const response = await api.put('cyclops/live_streams', body, token, 'v3');

  if (!response.ok) {
    const json = await response.json();
    throw Error(json.error);
  }

  return response.json();
}

async function deleteLivestreamApi(body, token) {
  await api.del('cyclops/live_streams', body, token, 'v3', true);
}

async function startOrStopLivestreamApi(body, token) {
  await api.patch(`cyclops/live_streams/${body.Id}/start_or_stop_stream`, body, token, 'v3');
}

async function bypassPipelineLivestreamApi(body, token) {
  await api.patch(`cyclops/live_streams/${body.Id}/bypass_pipeline`, body, token, 'v3');
}

async function startStreamRippersLivestreamApi(body, token) {
  await api.patch(`cyclops/live_streams/${body.Id}/init_stream_ripping`, body, token, 'v3');
}

// Helper
async function fetchCompanyLivestreams(dispatch, args, token, noLoader) {
  try {
    const response = await getCompanyLivestreams(args, token);

    if (!noLoader) {
      dispatch(setFaviconDefault());
      dispatch(setFetchLoaderReset());
    }

    dispatch(cyclopsLivestreamsDataLoaded(response));
  } catch (e) {
    if (e.name !== 'AbortError') {
      if (!noLoader) {
        dispatch(setFaviconDefault());
        dispatch(setFetchLoaderReset());
      }

      dispatch(arriveNotice(e.message));
    }
  }
}

async function submitCompanyLivestream(dispatch, body, token, redirectPath) {
  try {
    await submitNewLivestreamApi(body, token);

    dispatch(arriveNotice('Live stream added', 'ok'));
    dispatch(setSubmissionLoaderReset());
    dispatch(initiateRedirectRequest(null, redirectPath, true));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setSubmissionLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

async function editCompanyLivestream(dispatch, body, token, redirectPath) {
  try {
    await editLivestreamApi(body, token);

    dispatch(arriveNotice('Changes saved', 'ok'));
    dispatch(setSubmissionLoaderReset());
    dispatch(initiateRedirectRequest(null, redirectPath, true));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setSubmissionLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

async function deleteCompanyLivestream(dispatch, body, token, redirectPath) {
  try {
    deleteLivestreamApi(body, token);

    dispatch(arriveNotice('Live stream deleted', 'ok'));
    dispatch(setSubmissionLoaderReset());
    dispatch(initiateRedirectRequest(null, redirectPath, true));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setSubmissionLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

async function startOrStopCompanyLivestream(dispatch, body, token, redirectPath) {
  try {
    await startOrStopLivestreamApi(body, token);
    const notice = body.start ? 'Live stream started' : 'Live stream stopped';

    dispatch(arriveNotice(notice, 'ok'));
    dispatch(setSubmissionLoaderReset());
    dispatch(initiateRedirectRequest(null, redirectPath, true));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setSubmissionLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

async function bypassPipelineCompanyLivestream(dispatch, body, token, redirectPath) {
  try {
    await bypassPipelineLivestreamApi(body, token);

    dispatch(arriveNotice('Successfully reset media pipeline', 'ok'));
    dispatch(setSubmissionLoaderReset());
    dispatch(initiateRedirectRequest(null, redirectPath, true));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setSubmissionLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

async function startStreamRippersCompanyLivestream(dispatch, body, token, redirectPath) {
  try {
    await startStreamRippersLivestreamApi(body, token);

    dispatch(arriveNotice('Stream ripping started', 'ok'));
    dispatch(setSubmissionLoaderReset());
    dispatch(initiateRedirectRequest(null, redirectPath, true));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setSubmissionLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

async function fetchLivestreamDetails(dispatch, id, args, token) {
  try {
    const response = await getLivestreamDetails(id, args, token);

    dispatch(setFaviconDefault());
    dispatch(setFetchLoaderReset());
    dispatch(cyclopsLivestreamDetailsLoaded(response));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setFaviconDefault());
      dispatch(setFetchLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

// Public components actions
export function clearCyclopsLivestreamsRequest() {
  return (dispatch) => {
    dispatch(cyclopsLivestreamsDataClear());
  };
}

export function initiateCyclopsLivestreamsRequest(args, noLoader = false) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      favicon: { faviconStatus },
      fetchLoader: { dataLoading },
    } = getState();

    if (!dataLoading && !noLoader) dispatch(setFetchLoaderLoading());

    if (faviconStatus !== 'loading' && !noLoader) {
      dispatch(setFaviconLoading());
    }

    await fetchCompanyLivestreams(dispatch, args, token, noLoader);
  };
}

export function submitNewCyclopsLivestreamRequest(body, redirectPath) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      fetchLoader: { submissionLoading },
    } = getState();

    if (!submissionLoading) dispatch(setSubmissionLoaderLoading());

    await submitCompanyLivestream(dispatch, body, token, redirectPath);
  };
}

export function editCyclopsLivestreamRequest(body, redirectPath) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      fetchLoader: { submissionLoading },
    } = getState();

    if (!submissionLoading) dispatch(setSubmissionLoaderLoading());

    await editCompanyLivestream(dispatch, body, token, redirectPath);
  };
}

export function deleteCyclopsLivestreamRequest(body, redirectPath) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      fetchLoader: { submissionLoading },
    } = getState();

    if (!submissionLoading) dispatch(setSubmissionLoaderLoading());

    await deleteCompanyLivestream(dispatch, body, token, redirectPath);
  };
}

export function startOrStopCyclopsLivestreamRequest(body, redirectPath) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      fetchLoader: { submissionLoading },
    } = getState();

    if (!submissionLoading) dispatch(setSubmissionLoaderLoading());

    const notice = body.start ? 'Starting live stream...' : 'Stopping live stream...';
    dispatch(arriveNotice(notice, 'ok'));

    await startOrStopCompanyLivestream(dispatch, body, token, redirectPath);
  };
}

export function bypassPipelineCyclopsLivestreamRequest(body, redirectPath) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      fetchLoader: { submissionLoading },
    } = getState();

    if (!submissionLoading) dispatch(setSubmissionLoaderLoading());

    await bypassPipelineCompanyLivestream(dispatch, body, token, redirectPath);
  };
}

export function clearCyclopsLivestreamDetailsRequest() {
  return (dispatch) => {
    dispatch(cyclopsLivestreamDetailsClear());
  };
}

export function initiateCyclopsLivestreamDetailsRequest(id, args) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      favicon: { faviconStatus },
      fetchLoader: { dataLoading },
    } = getState();

    if (!dataLoading) dispatch(setFetchLoaderLoading());

    if (faviconStatus !== 'loading') {
      dispatch(setFaviconLoading());
    }

    await fetchLivestreamDetails(dispatch, id, args, token);
  };
}

export function startStreamRippersCyclopsLivestreamRequest(body, redirectPath) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      fetchLoader: { submissionLoading },
    } = getState();

    if (!submissionLoading) dispatch(setSubmissionLoaderLoading());

    await startStreamRippersCompanyLivestream(dispatch, body, token, redirectPath);
  };
}
