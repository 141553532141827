export default {
  root: '/',
  login: '/login',
  forgotPassword: '/forgot-password',
  recoverAccount: '/recover-account',
  invited: '/invited',
  account: '/account',
  users: '/users',
  settings: '/settings/:id',
  productNewSearch: '/new',
  productResultsUpload: '/upload-results',
  productReview: '/review',
  productListSearches: '/searches/:status',
  productSearchResults: '/results/:id',
  productPiracyOverview: '/piracy-overview',
  productReports: '/reports',
  productReport: '/reports/:id',
  sharedReport: '/shared-report',
  cyclopsAnalytics: '/cyclops/analytics',
  cyclopsEventsList: '/cyclops/events/:status',
  cyclopsEventDetails: '/cyclops/events/results/:id',
  cyclopsSettings: '/cyclops/settings',
  cyclopsOrganizations: '/cyclops/organizations',
};
