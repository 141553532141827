import React from 'react';
import styled from 'styled-components';

import {
  Box, Heading, FormField, TextInput,
} from 'grommet';
import { AppAnchor, AppButton } from '@Components/Control';

const StyledForm = styled(Box)`
  max-width: 100%;
  width: calc(60% * 2 / 5);
  min-height: 21rem;
  background-color: white;
  box-shadow: 0px 2px 7px 0px rgba(8, 0, 102, 0.18);
  border-radius: 10px;

  @media (max-width: 86.875em) {
    width: 450px;
  }
`;

const StyledTitle = styled(Heading)`
  letter-spacing: normal;
  line-height: 1.35;
  font-weight: 600;
  margin: 0;
`;

const StyledButton = styled((props) => <AppButton type="submit" color="#343D62" margin={{ top: '2.4rem' }} {...props} />)`
  && { width: 100%; }
`;

const StyledAnchor = styled((props) => <AppAnchor {...props} />)`
  && {
    text-align: center;
    color: #038BFC;
    display: block;
    font-size: 0.85rem;
  }
`;

const StyledField = styled(FormField)`
  margin-bottom: 1.25rem;

  > div {
    border-bottom: none;
  }
`;

const StyledFieldRow = styled(Box)`
  margin-bottom: 0;

  &:not(:last-of-type) { margin-bottom: 2rem; }
  > ${StyledField} { min-width: 40%; }

  @media (min-width: 48.0625em) {
    > ${StyledField} { margin: 0; }
  }
`;

const StyledFormInput = styled(TextInput)`
  background: white;
  font-size: 1rem;
  border: solid 1px #D0D0D0;
  color: var(--primary-text);
  padding: 0.65rem 1rem;
  border-radius: 5px;
  width: 100%;

  &:focus { border-color: #038BFC; }
`;

export {
  StyledForm,
  StyledTitle,
  StyledFieldRow,
  StyledField,
  StyledAnchor,
  StyledButton,
  StyledFormInput,
};
