import {
  AUTH_LOG_IN,
  AUTH_LOG_OUT,
  AUTH_REFRESH,
  AUTH_ACCOUNT_LOADED,
  AUTH_ACCOUNT_CLEAR,
  AUTH_USERS_LOADED,
  AUTH_USERS_CLEAR,
  AUTH_INVITE_TOKEN_LOADED,
  AUTH_INVITE_TOKEN_CLEAR,
} from '../actionTypes';

import * as api from '../apiHelper';
import { initiateRedirectRequest } from '../redirectActions';
import { setAppLoaderReset, setAppLoaderReady } from '../appLoaderActions';
import { arriveNotice } from '../noticeActions';

// Reducer corresponding actions
function userLogIn(userJson, token) {
  const {
    email,
    firstName,
    lastName,
    company: {
      name: companyName,
      id: companyId,
      logoUrl: companyLogo,
    },
    featuresList: companyFeatures,
    preferences: {
      blacklightCanExport: exportable,
      blacklightSupportAccount: withAccount,
    },
  } = userJson;

  return {
    type: AUTH_LOG_IN,
    meta: { crossTab: true },
    payload: {
      email,
      firstName,
      lastName,
      companyName,
      companyId,
      companyLogo,
      companyFeatures,
      exportable,
      withAccount,
      token,
    },
  };
}

function userLogOut() {
  return { type: AUTH_LOG_OUT, meta: { crossTab: true } };
}

function userRefresh(userJson, token) {
  const {
    email,
    firstName,
    lastName,
    company: {
      name: companyName,
      id: companyId,
      logoUrl: companyLogo,
    },
    featuresList: companyFeatures,
    preferences: {
      blacklightCanExport: exportable,
      blacklightSupportAccount: withAccount,
    },
  } = userJson;

  return {
    type: AUTH_REFRESH,
    meta: { crossTab: true },
    payload: {
      email,
      firstName,
      lastName,
      companyName,
      companyId,
      companyLogo,
      companyFeatures,
      exportable,
      withAccount,
      token,
    },
  };
}

function userAccountLoaded(account) {
  return {
    type: AUTH_ACCOUNT_LOADED,
    payload: { account },
  };
}

function userAccountClear() {
  return { type: AUTH_ACCOUNT_CLEAR };
}

function usersLoaded(users) {
  return {
    type: AUTH_USERS_LOADED,
    payload: { users },
  };
}

function usersClear() {
  return { type: AUTH_USERS_CLEAR };
}

function inviteTokenLoaded(tokenData) {
  return {
    type: AUTH_INVITE_TOKEN_LOADED,
    payload: { tokenData },
  };
}

function inviteTokenClear() {
  return { type: AUTH_INVITE_TOKEN_CLEAR };
}

// API
async function logInApi(email, password) {
  const body = {
    user: {
      email,
      password,
    },
  };

  const response = await api.post('users/sign_in', body, null, 'v1');
  const json = await response.json();
  const token = response.headers.get('Authorization').replace('Bearer ', '');

  return { json, token };
}

async function logOutApi(token) {
  await api.del('users/sign_out', {}, token, 'v1', true);
}

async function refreshApi(token) {
  const response = await api.get('users', null, token, 'v3');
  return response.json();
}

async function uploadCompanyLogo(imageFile, token) {
  await api.uploadFile('companies/upload_logo', imageFile, token, 'v3');
}

async function sendPasswordInstructionsApi(email) {
  const response = await api.post('users/password', { email }, null, 'v1');
  return response.json();
}

async function updatePasswordApi(password, resetPasswordToken) {
  const response = await api.put('users/password', { password, resetPasswordToken }, null, 'v1');
  return response.json();
}

async function getAccountApi(token) {
  const response = await api.get('users/account', null, token, 'v1');
  return response.json();
}

async function getUsersApi(token) {
  const response = await api.get('users', null, token, 'v4');
  return response.json();
}

async function deleteUserApi(userId, token) {
  await api.del(`users/${userId}`, null, token, 'v4');
}

async function createUserApi(body, token) {
  await api.post('users', body, token, 'v4');
}

async function resendUserInviteApi(userId, token) {
  await api.post(`users/${userId}/reinvite`, null, token, 'v4');
}

async function getInviteTokenApi(token) {
  const response = await api.get('users/invite_token', null, token, 'v4');
  return response.json();
}

async function createInvitedUserApi(body) {
  const response = await api.customErrorPost('users/invited', body, null, 'v4');
  const responseJson = response.json();

  if (!response.ok) {
    return responseJson.then((json) => {
      let customError = 'Error creating user!';
      if (json?.errors?.email) {
        customError = `Email ${json.errors.email[0]}`;
      }

      if (json?.errors?.password) {
        customError = `Password ${json.errors.password[0]}`;
      }

      if (json?.errors?.token) {
        customError = `Token ${json.errors.token[0]}`;
      }

      throw new Error(customError);
    });
  }

  return true;
}

// Public components actions
export function logInRequest(email, password) {
  return async (dispatch) => {
    try {
      dispatch(setAppLoaderReady());
      const { json, token } = await logInApi(email, password);
      dispatch(userLogIn(json, token));
      dispatch(setAppLoaderReset());
    } catch (e) {
      dispatch(arriveNotice(e.message));
      dispatch(setAppLoaderReset());
    }
  };
}

export function logOutRequest() {
  return async (dispatch, getState) => {
    try {
      dispatch(setAppLoaderReady());
      await logOutApi(getState().auth.token);
      dispatch(userLogOut());
      dispatch(setAppLoaderReset());
    } catch (e) {
      dispatch(arriveNotice(e.message));
      dispatch(setAppLoaderReset());
    }
  };
}

export function refreshRequest() {
  return async (dispatch, getState) => {
    try {
      const { token } = getState().auth;
      const json = await refreshApi(token);
      dispatch(userRefresh(json, token));
    } catch (e) {
      if (e.name !== 'AbortError') {
        dispatch(userLogOut());
        dispatch(arriveNotice(e.message));
      }
    }
  };
}

export function uploadCompanyLogoRequest(imageFile) {
  return async (dispatch, getState) => {
    try {
      const { token } = getState().auth;
      await uploadCompanyLogo(imageFile, token);
      const json = await refreshApi(token);

      dispatch(arriveNotice('Company logo uploaded successfully!', 'ok'));
      dispatch(userRefresh(json, token));
    } catch (e) {
      if (e.name !== 'AbortError') {
        dispatch(arriveNotice(e.message));
      }
    }
  };
}

export function sendPasswordResetInstructionsRequest(email, rootPath) {
  return async (dispatch) => {
    try {
      dispatch(setAppLoaderReady());
      const response = await sendPasswordInstructionsApi(email);

      dispatch(arriveNotice(response.notice, 'ok'));
      dispatch(initiateRedirectRequest(null, rootPath));
      dispatch(setAppLoaderReset());
    } catch (e) {
      dispatch(arriveNotice(e.message));
      dispatch(setAppLoaderReset());
    }
  };
}

export function updatePasswordRequest(password, resetPasswordToken, loginPath) {
  return async (dispatch) => {
    try {
      dispatch(setAppLoaderReady());
      const response = await updatePasswordApi(password, resetPasswordToken);

      dispatch(arriveNotice(response.notice, 'ok'));
      dispatch(initiateRedirectRequest(null, loginPath));
      dispatch(setAppLoaderReset());
    } catch (e) {
      dispatch(arriveNotice(e.message));
      dispatch(setAppLoaderReset());
    }
  };
}

export function clearAccountRequest() {
  return (dispatch) => {
    dispatch(userAccountClear());
  };
}

export function fetchAccountRequest() {
  return async (dispatch, getState) => {
    try {
      dispatch(userAccountClear());

      const { token } = getState().auth;
      const json = await getAccountApi(token);
      dispatch(userAccountLoaded(json.products));
    } catch (e) {
      if (e.name !== 'AbortError') {
        dispatch(arriveNotice(e.message));
      }
    }
  };
}

export function clearUsersRequest() {
  return (dispatch) => {
    dispatch(usersClear());
  };
}

export function fetchUsersRequest() {
  return async (dispatch, getState) => {
    try {
      dispatch(usersClear());

      const { token } = getState().auth;
      const json = await getUsersApi(token);
      dispatch(usersLoaded(json));
    } catch (e) {
      if (e.name !== 'AbortError') {
        dispatch(arriveNotice(e.message));
      }
    }
  };
}

export function deleteUserRequest(userId, redirectPath, afterAction) {
  return async (dispatch, getState) => {
    try {
      const { token } = getState().auth;
      await deleteUserApi(userId, token);

      dispatch(arriveNotice('User deleted successfully!', 'ok'));
      dispatch(initiateRedirectRequest(null, redirectPath, true));
      afterAction();
    } catch (e) {
      if (e.name !== 'AbortError') {
        dispatch(arriveNotice(e.message));
      }
    }
  };
}

export function createNewUserRequest(body, redirectPath, afterAction) {
  return async (dispatch, getState) => {
    try {
      const { token } = getState().auth;
      await createUserApi(body, token);

      dispatch(arriveNotice('New user invitation sent!', 'ok'));
      dispatch(initiateRedirectRequest(null, redirectPath, true));
      afterAction();
    } catch (e) {
      if (e.name !== 'AbortError') {
        dispatch(arriveNotice(e.message));
      }
    }
  };
}

export function resendUserInviteRequest(userId, redirectPath, afterAction) {
  return async (dispatch, getState) => {
    try {
      const { token } = getState().auth;
      await resendUserInviteApi(userId, token);

      dispatch(arriveNotice('Invitation sent successfully!', 'ok'));
      dispatch(initiateRedirectRequest(null, redirectPath, true));
      afterAction();
    } catch (e) {
      if (e.name !== 'AbortError') {
        dispatch(arriveNotice(e.message));
      }
    }
  };
}

export function clearInviteTokenRequest() {
  return (dispatch) => {
    dispatch(inviteTokenClear());
  };
}

export function fetchInviteTokenRequest() {
  return async (dispatch, getState) => {
    try {
      dispatch(inviteTokenClear());

      const { token } = getState().auth;
      const json = await getInviteTokenApi(token);
      dispatch(inviteTokenLoaded(json));
    } catch (e) {
      if (e.name !== 'AbortError') {
        dispatch(arriveNotice(e.message));
      }
    }
  };
}

export function createInvitedUserRequest(body, redirectPath) {
  return async (dispatch) => {
    try {
      const result = await createInvitedUserApi(body);

      if (result) {
        dispatch(arriveNotice('Signup successful!', 'ok'));
        dispatch(initiateRedirectRequest(null, redirectPath, true));
      }
    } catch (e) {
      if (e.name !== 'AbortError') {
        dispatch(arriveNotice(e.message));
      }
    }
  };
}
