import React from 'react';
import PropTypes from 'prop-types';
import Case from 'case';
import _ from 'lodash';

import {
  Box, Text, Layer, Button,
} from 'grommet';
import { Alert, Robot } from 'grommet-icons';

import { CopyButton, AppButton } from '@Components/Control';
import { localAsset } from '@Helpers/asset';
import { NoDataPlaceholder, ModalSelectWrapper } from '../SharedComponents';
import {
  StyledCyclopsHeading, StyledSVG, StyledHoverButton, StyledTooltip,
} from './StyledCyclopsComponents';


const DetailRow = ({
  small,
  dataKey,
  dataValue,
  label,
  primaryText,
  secondaryText,
  menuButtonHighlight,
  hideTooltip = false,
  layout = 'row',
}) => {
  const handleValue = (key, value) => {
    switch (key) {
      case 'createdAt':
      case 'updatedAt':
      case 'startDateTime':
      case 'endDateTime':
        return (
          new Date(value).toLocaleString('en-US', {
            month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric',
          })
        );

      case 'encoderOutboundProtocol':
        return Case.upper(value);

      case 'piracySearchTerms':
        return value.join(', ');

      default:
        return value;
    }
  };

  return (
    <Box
      direction={layout}
      gap={layout === 'row' ? '0.5rem' : '0.25rem'}
      align={layout === 'row' ? 'center' : 'start'}
      pad={{ top: small ? '1rem' : '0.65rem' }}
      height={{ min: 'fit-content' }}
    >
      <Text size="1rem" color={secondaryText} weight={600}>
        {label}
      </Text>
      <Box direction="row" gap="0.5rem" align="center">
        <Text color={primaryText} size="1rem">
          {handleValue(dataKey, dataValue)}
        </Text>
        <Box flex={false} pad={{ left: '0.25rem', top: '0.15rem' }}>
          <CopyButton
            renderKey={dataKey}
            value={handleValue(dataKey, dataValue)}
            color={primaryText}
            hoverColor={menuButtonHighlight}
            tooltipColor={menuButtonHighlight}
            tooltipPosition="right"
            data-offset="{'right': 10}"
            hideTooltip={hideTooltip}
          />
        </Box>
      </Box>
    </Box>
  );
};

DetailRow.propTypes = {
  small: PropTypes.bool.isRequired,
  dataKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  dataValue: PropTypes.string.isRequired,
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string.isRequired,
  menuButtonHighlight: PropTypes.string.isRequired,
  hideTooltip: PropTypes.bool,
  layout: PropTypes.string,
};

const DynamicConfigModal = ({
  small, textColor, secondaryTextColor, buttonHighlight, modalBg, data,
}) => {
  const [showModal, setShowModal] = React.useState(false);

  const dataKeys = Object.keys(data);

  const renderNestedData = (dataKey) => {
    let useValue = data[dataKey];

    if (typeof useValue === 'object') {
      useValue = JSON.stringify(useValue);
    }

    if (Array.isArray(useValue)) {
      useValue = useValue.join(', ');
    }

    if (!useValue) return null;

    return (
      <DetailRow
        hideTooltip
        key={dataKey}
        small={small}
        dataKey={dataKey}
        label={`${Case.title(dataKey)}:`}
        dataValue={useValue}
        primaryText={textColor}
        secondaryText={secondaryTextColor}
        menuButtonHighlight={buttonHighlight}
        layout="column"
      />
    );
  };

  return (
    <Box>
      <AppButton
        overrideHover
        onClick={() => setShowModal(true)}
        bgColor={buttonHighlight}
        textColor={textColor}
        level="dynamicSmall"
      >
        <Text size="1rem" color={textColor}>View config</Text>
      </AppButton>
      {showModal && (
        <Layer
          responsive={false}
          animate={false}
          onEsc={() => setShowModal(false)}
          onClickOutside={() => setShowModal(false)}
        >
          <ModalSelectWrapper
            small={small}
            setShowModal={() => setShowModal()}
            handleQueryUpdate={() => null}
            textColor={textColor}
            buttonHighlight={buttonHighlight}
            modalBg={modalBg}
            label="Protocol Configuration"
            modalCloseMessage="Close"
          >
            <Box
              direction="column"
              overflow={{ vertical: 'scroll' }}
              pad={{ vertical: '1rem', right: '0.5rem' }}
              height={{ max: '70vh' }}
            >
              {dataKeys.map((key) => renderNestedData(key))}
            </Box>
          </ModalSelectWrapper>
        </Layer>
      )}
    </Box>
  );
};

DynamicConfigModal.propTypes = {
  small: PropTypes.bool.isRequired,
  textColor: PropTypes.string.isRequired,
  secondaryTextColor: PropTypes.string.isRequired,
  buttonHighlight: PropTypes.string.isRequired,
  modalBg: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

const EventDetailsPartial = ({
  small,
  loading,
  eventUid,
  startOrStopLivestream,
  bypassPipelineLivestream,
  startStreamRippers,
  formattedDate = null,
  authPagesConfig,
  eventData = null,
  companyOrganizations = null,
}) => {
  const {
    darkModeColors: {
      primaryDarkBg, containerBg, primaryText, menuButtonHighlight, secondaryText, decrementText,
    },
    cyclopsStatusDisplayMap,
  } = authPagesConfig;

  const renderDetailRow = (key, label, value) => {
    if (!value) return null;

    return (
      <DetailRow
        small={small}
        dataKey={key}
        label={label}
        dataValue={value}
        primaryText={primaryText}
        secondaryText={secondaryText}
        menuButtonHighlight={menuButtonHighlight}
      />
    );
  };

  const renderContent = () => {
    if (loading || !eventData) {
      return (
        <NoDataPlaceholder
          noShadow
          darkmode
          backgroundColor={containerBg}
          buttonHighlight={menuButtonHighlight}
          loading={loading}
          label="Unable to load event details"
          textColor={primaryText}
          loaderColor={menuButtonHighlight}
        />
      );
    }

    return (
      <Box direction="column" background={containerBg} pad="1rem" round="10px">
        <Box
          fill="horizontal"
          border={{
            color: '#394658', size: '2px', style: 'solid', side: 'bottom',
          }}
          pad={{ bottom: '0.65rem' }}
        >
          <Text size="1.25rem" weight={600} color={primaryText} textAlign="start">
            Live stream event details
          </Text>
        </Box>
        <Box direction="column">
          {renderDetailRow('startDateTime', 'Start date & time:', eventData.startDateTime)}
          {renderDetailRow('endDateTime', 'End date & time:', eventData.endDateTime)}
          {renderDetailRow('updatedAt', 'Updated at:', eventData.updatedAt)}
          {renderDetailRow('encoderOutboundProtocol', 'Encoder outbound protocol:', eventData.encoderOutboundProtocol)}
          {renderDetailRow('clientMediaId', 'Client media ID:', eventData.clientMediaId)}
          {renderDetailRow('currentOriginManifestPath', 'Origin manifest path:', eventData.currentOriginManifestPath)}
          {renderDetailRow('currentHostVideoBaseFolder', 'Host video base folder:', eventData.currentHostVideoBaseFolder)}
          {renderDetailRow('piracySearchTerms', 'Piracy search terms:', eventData.piracySearchTerms)}
          {eventData.protocolConfig && !_.isEmpty(eventData.protocolConfig) && (
            <Box direction="row" gap="0.5rem" align="center" pad={{ top: small ? '1rem' : '0.65rem' }}>
              <Text size="1rem" color={secondaryText} weight={600}>
                Protocol configuration:
              </Text>
              <DynamicConfigModal
                small={small}
                textColor={primaryText}
                secondaryTextColor={secondaryText}
                buttonHighlight={menuButtonHighlight}
                modalBg={containerBg}
                data={eventData.protocolConfig}
              />
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const activeOrganization = companyOrganizations?.find(({ Id }) => (
    Id === eventData?.contentOwnerId
  ));
  let activeTitle = activeOrganization ? `${activeOrganization?.name} - ${eventData?.name}` : eventData?.name;

  if (!loading && !eventData?.name) {
    activeTitle = activeOrganization ? `${activeOrganization?.name} - Event not found` : 'Event not found';
  }

  const pageTitle = loading ? 'Loading event details...' : activeTitle;

  let actionUid = null;

  if (eventData?.status === 'live') {
    actionUid = 'stop';
  } else if (eventData?.status === 'ready') {
    actionUid = 'start';
  }

  const renderStatus = (status) => {
    let useStatus = Case.title(status);

    if (cyclopsStatusDisplayMap[status]) {
      useStatus = cyclopsStatusDisplayMap[status];
    }

    return useStatus;
  };

  const actionBoolean = actionUid && actionUid === 'start';
  const actionText = actionBoolean ? 'Start stream' : 'Stop stream';
  const pipelineResetWarning = 'Caution! This will reset the media pipeline configuration for this livestream.';

  const showStartStreamRippers = eventData?.piracySearchStatus === 'not_started';

  return (
    <Box background={primaryDarkBg} direction="column" gap="1rem" pad={{ top: small ? '0' : '1rem' }}>
      <Box direction={small ? 'column' : 'row'} gap={small ? '1rem' : '2rem'} align="start" justify="between">
        <Box direction="column" gap="1rem" align="start">
          <StyledCyclopsHeading noMargin level={2} color={primaryText}>
            {pageTitle}
          </StyledCyclopsHeading>
          {!loading && eventData && (
            <Box direction="column" gap="0.5rem" width="100%" align="start">
              <Button
                plain
                data-tip
                data-for="bypass-media-pipeline"
                key="bypass-media-pipeline"
                onClick={() => bypassPipelineLivestream(eventUid)}
                style={{
                  backgroundColor: decrementText, width: 'fit-content', padding: '0.45rem 0.6rem', borderRadius: '5px',
                }}
              >
                <Box direction="row" gap="0.5rem" align="center">
                  <Alert color={primaryText} size="1rem" />
                  <Text size="1rem" color={primaryText}>Bypass</Text>
                </Box>
                <StyledTooltip id="bypass-media-pipeline" place="bottom">
                  <Box pad={{ vertical: '0.2rem' }} width={{ max: '10rem' }}>
                    <Text size="1rem" color={primaryText}>
                      {pipelineResetWarning}
                    </Text>
                  </Box>
                </StyledTooltip>
              </Button>
              {small && (
                <Box width="65%">
                  <Text size="0.8rem" color={primaryText}>
                    {pipelineResetWarning}
                  </Text>
                </Box>
              )}
            </Box>
          )}
        </Box>
        {!loading && eventData && (
          <Box flex={false} direction="column" gap="1rem" align={small ? 'start' : 'end'}>
            <Box
              flex={false}
              background={containerBg}
              width={small ? '100%' : 'fit-content'}
              pad={small ? '1rem' : { horizontal: '1rem', vertical: '0.5rem' }}
              round="10px"
              direction="column"
              gap="0.5rem"
            >
              <Box wrap={small} direction="row" gap={small ? '1rem' : '1.5rem'} justify="between" align="center">
                <Box direction="row" gap="0.5rem" align="center">
                  <Text size="1rem" color={secondaryText} weight={600}>
                    Event status:
                  </Text>
                  <Text color={primaryText} size="1rem">
                    {eventData?.status ? renderStatus(eventData.status) : 'Incorrect configuration'}
                  </Text>
                </Box>
                {actionUid && (
                  <StyledHoverButton
                    plain
                    key="start-or-stop-stream"
                    onClick={() => startOrStopLivestream(eventUid, actionBoolean)}
                    hoverColor={menuButtonHighlight}
                  >
                    <Box direction="row" gap="0.5rem" align="center">
                      <StyledSVG
                        src={localAsset('images/power-symbol-icon.svg')}
                        height={small ? '1.1rem' : '1rem'}
                        width={small ? '1.1rem' : '1rem'}
                        style={{ paddingTop: '0.2rem' }}
                        $fillColor="white"
                      />
                      <Text size="1rem" color={primaryText}>{actionText}</Text>
                    </Box>
                  </StyledHoverButton>
                )}
              </Box>
              <Box wrap={small} direction="row" gap={small ? '1rem' : '1.5rem'} justify="between" align="center">
                <Box direction="row" gap="0.5rem" align="center">
                  <Text size="1rem" color={secondaryText} weight={600}>
                    Piracy streams status:
                  </Text>
                  <Text color={primaryText} size="1rem">
                    {eventData?.piracyStreamsStatus ? renderStatus(eventData.piracyStreamsStatus) : 'N/A'}
                  </Text>
                </Box>
                {showStartStreamRippers && (
                  <StyledHoverButton
                    plain
                    key="start-stream-rippers"
                    onClick={() => startStreamRippers(eventUid)}
                    hoverColor={menuButtonHighlight}
                  >
                    <Box direction="row" gap="0.5rem" align="center" pad={{ vertical: '0.1rem' }}>
                      <Robot color={primaryText} size="1.1rem" />
                      <Text size="1rem" color={primaryText}>Start stream rippers</Text>
                    </Box>
                  </StyledHoverButton>
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Box pad={{ top: '1rem' }} direction="column" gap="1rem">
        {eventData?.status === 'live' && (
          <Text color={secondaryText} size="1rem" weight={500}>
            {formattedDate}
          </Text>
        )}
        {renderContent()}
      </Box>
    </Box>
  );
};

EventDetailsPartial.propTypes = {
  small: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  formattedDate: PropTypes.string.isRequired,
  eventUid: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    constructionImage: PropTypes.string.isRequired,
    darkModeColors: PropTypes.shape({
      containerBg: PropTypes.string.isRequired,
      primaryDarkBg: PropTypes.string.isRequired,
      primaryText: PropTypes.string.isRequired,
      secondaryText: PropTypes.string.isRequired,
      menuButtonHighlight: PropTypes.string.isRequired,
      decrementText: PropTypes.string.isRequired,
    }).isRequired,
    cyclopsStatusDisplayMap: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  }).isRequired,
  eventData: PropTypes.shape({
    Id: PropTypes.string.isRequired,
    maxVideoHeight: PropTypes.string,
    maxVideoWidth: PropTypes.string,
    contentOwnerId: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    encoderOutboundProtocol: PropTypes.string.isRequired,
    urlOrCidrs: PropTypes.string,
    currentOriginManifestPath: PropTypes.string.isRequired,
    currentHostVideoBaseFolder: PropTypes.string.isRequired,
    clientMediaId: PropTypes.string,
    protocolConfig: PropTypes.object,
    endDateTime: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    startDateTime: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    expectedViewership: PropTypes.number.isRequired,
    backupOriginUrl: PropTypes.string,
    piracySearchStatus: PropTypes.string,
    piracySearchTerms: PropTypes.arrayOf(PropTypes.string),
  }),
  companyOrganizations: PropTypes.arrayOf(PropTypes.any),
  startOrStopLivestream: PropTypes.func.isRequired,
  bypassPipelineLivestream: PropTypes.func.isRequired,
  startStreamRippers: PropTypes.func.isRequired,
};

export default EventDetailsPartial;
