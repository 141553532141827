import {
  CYCLOPS_ORGANIZATIONS_DATA_LOADED,
  CYCLOPS_ORGANIZATIONS_DATA_CLEAR,
  CYCLOPS_ACTIVE_ORGANIZATION_SET,
  CYCLOPS_ACTIVE_ORGANIZATION_CLEAR,
  CYCLOPS_CDN_CONSTANTS_LOADED,
  CYCLOPS_CDN_CONSTANTS_CLEAR,
  CYCLOPS_CONSTANTS_CONFIG_LOADED,
  CYCLOPS_CONSTANTS_CONFIG_CLEAR,
} from '../../actionTypes';

import * as api from '../../apiHelper';
import { initiateRedirectRequest } from '../../redirectActions';
import {
  setFetchLoaderLoading,
  setFetchLoaderReset,
  setSubmissionLoaderLoading,
  setSubmissionLoaderReset,
} from '../../fetchLoaderActions';
import { arriveNotice } from '../../noticeActions';
import { setFaviconLoading, setFaviconDefault } from '../../faviconActions';


// Reducer corresponding actions
function cyclopsCompanyOrganizationsClear() {
  return { type: CYCLOPS_ORGANIZATIONS_DATA_CLEAR };
}

function cyclopsCompanyOrganizationsLoaded(companyOrganizations) {
  return {
    type: CYCLOPS_ORGANIZATIONS_DATA_LOADED,
    payload: { companyOrganizations },
  };
}

function cyclopsActiveOrganizationClear() {
  return { type: CYCLOPS_ACTIVE_ORGANIZATION_CLEAR };
}

function cyclopsActiveOrganizationSet(activeOrganization) {
  return {
    type: CYCLOPS_ACTIVE_ORGANIZATION_SET,
    payload: { activeOrganization },
  };
}

function cyclopsCDNConstantsClear() {
  return { type: CYCLOPS_CDN_CONSTANTS_CLEAR };
}

function cyclopsCDNConstantsLoaded(cdnConstants) {
  return {
    type: CYCLOPS_CDN_CONSTANTS_LOADED,
    payload: { cdnConstants },
  };
}

function cyclopsConstantsConfigClear() {
  return { type: CYCLOPS_CONSTANTS_CONFIG_CLEAR };
}

function cyclopsConstantsConfigLoaded(constantsConfig) {
  return {
    type: CYCLOPS_CONSTANTS_CONFIG_LOADED,
    payload: { constantsConfig },
  };
}

// API
async function getCompanyOrganizations(args, token) {
  const response = await api.nestedParamsGet('cyclops/content_owners', args, token, 'v3');
  return response.json();
}

async function submitNewOrganizationApi(body, token) {
  const response = await api.post('cyclops/content_owners', body, token, 'v3');

  if (!response.ok) {
    const json = await response.json();
    throw Error(json.error);
  }

  return response.json();
}

async function editOrganizationApi(id, body, token) {
  const response = await api.put(`cyclops/content_owners/${id}`, body, token, 'v3');

  if (!response.ok) {
    const json = await response.json();
    throw Error(json.error);
  }

  return response.json();
}

async function deleteOrganizationApi(orgId, body, token) {
  await api.del(`cyclops/content_owners/${orgId}`, body, token, 'v3', true);
}

async function uploadOrganizationVodApi(orgId, body, token) {
  await api.uploadFile(`cyclops/content_owners/${orgId}/upload_vod`, body, token, 'v3', 'PATCH');
}

async function updateOrganizationCDNSettingsApi(orgId, body, token) {
  await api.patch(`cyclops/content_owners/${orgId}/update_cdn_settings`, body, token, 'v3');
}

async function getCDNConstantsApi(orgId, args, token) {
  const response = await api.nestedParamsGet(`cyclops/content_owners/${orgId}/cdn_constants`, args, token, 'v3');
  return response.json();
}

async function getCyclopsConstantsConfigApi(args, token) {
  const response = await api.get('cyclops/constants', args, token, 'v3');
  return response.json();
}

// Helper
async function fetchCompanyOrganizations(dispatch, args, token) {
  try {
    const response = await getCompanyOrganizations(args, token);

    dispatch(setFaviconDefault());
    dispatch(setFetchLoaderReset());
    dispatch(cyclopsCompanyOrganizationsLoaded(response));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setFaviconDefault());
      dispatch(setFetchLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

async function submitCompanyOrganization(dispatch, body, token, redirectPath, afterAction) {
  try {
    await submitNewOrganizationApi(body, token);

    dispatch(arriveNotice('Organization added', 'ok'));
    dispatch(setSubmissionLoaderReset());
    dispatch(initiateRedirectRequest(null, redirectPath, true));
    afterAction();
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setSubmissionLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

async function editCompanyOrganization(dispatch, id, body, token, redirectPath, afterAction) {
  try {
    await editOrganizationApi(id, body, token);

    dispatch(arriveNotice('Changes saved', 'ok'));
    dispatch(setSubmissionLoaderReset());
    dispatch(initiateRedirectRequest(null, redirectPath, true));
    afterAction();
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setSubmissionLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

async function uploadCompanyVod(dispatch, orgId, body, token) {
  try {
    await uploadOrganizationVodApi(orgId, body, token);

    dispatch(arriveNotice('Vod uploaded successfully!', 'ok'));
    dispatch(setSubmissionLoaderReset());
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setSubmissionLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

async function updateCompanyCDNSettings(dispatch, orgId, body, token) {
  try {
    await updateOrganizationCDNSettingsApi(orgId, body, token);

    dispatch(arriveNotice('CDN settings updated', 'ok'));
    dispatch(setSubmissionLoaderReset());
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setSubmissionLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

async function fetchCDNConstants(dispatch, orgId, args, token) {
  try {
    const response = await getCDNConstantsApi(orgId, args, token);

    dispatch(setFaviconDefault());
    dispatch(setFetchLoaderReset());
    dispatch(cyclopsCDNConstantsLoaded(response));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setFaviconDefault());
      dispatch(setFetchLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

async function fetchCyclopsConstantsConfig(dispatch, args, token) {
  try {
    const response = await getCyclopsConstantsConfigApi(args, token);

    dispatch(setFaviconDefault());
    dispatch(setFetchLoaderReset());
    dispatch(cyclopsConstantsConfigLoaded(response));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setFaviconDefault());
      dispatch(setFetchLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

// Public components actions
export function clearCyclopsCompanyOrganizationsRequest() {
  return (dispatch) => {
    dispatch(cyclopsCompanyOrganizationsClear());
  };
}

export function initiateCyclopsCompanyOrganizationsRequest(args) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      favicon: { faviconStatus },
      fetchLoader: { dataLoading },
    } = getState();

    if (!dataLoading) dispatch(setFetchLoaderLoading());

    if (faviconStatus !== 'loading') {
      dispatch(setFaviconLoading());
    }

    await fetchCompanyOrganizations(dispatch, args, token);
  };
}

export function clearCyclopsActiveOrganizationRequest() {
  return (dispatch) => {
    dispatch(cyclopsActiveOrganizationClear());
  };
}

export function setCyclopsActiveOrganizationRequest(activeOrganization) {
  return (dispatch) => {
    dispatch(cyclopsActiveOrganizationSet(activeOrganization));
  };
}

export function submitNewCyclopsOrganizationRequest(body, redirectPath, afterAction) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      fetchLoader: { submissionLoading },
    } = getState();

    if (!submissionLoading) dispatch(setSubmissionLoaderLoading());

    await submitCompanyOrganization(dispatch, body, token, redirectPath, afterAction);
  };
}

export function editCyclopsOrganizationRequest(id, body, redirectPath, afterAction) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      fetchLoader: { submissionLoading },
    } = getState();

    if (!submissionLoading) dispatch(setSubmissionLoaderLoading());

    await editCompanyOrganization(dispatch, id, body, token, redirectPath, afterAction);
  };
}

export function deleteCyclopsOrganizationRequest(orgId, body, redirectPath, afterAction) {
  return async (dispatch, getState) => {
    try {
      const { token } = getState().auth;
      await deleteOrganizationApi(orgId, body, token);

      dispatch(arriveNotice('Organization deleted', 'ok'));
      dispatch(initiateRedirectRequest(null, redirectPath, true));
      afterAction();
    } catch (e) {
      dispatch(arriveNotice(e.message));
    }
  };
}

export function uploadCyclopsOrganizationVodRequest(orgId, body) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      fetchLoader: { submissionLoading },
    } = getState();

    if (!submissionLoading) dispatch(setSubmissionLoaderLoading());

    await uploadCompanyVod(dispatch, orgId, body, token);
  };
}

export function updateCyclopsOrganizationCDNSettingsRequest(orgId, body) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      fetchLoader: { submissionLoading },
    } = getState();

    if (!submissionLoading) dispatch(setSubmissionLoaderLoading());

    await updateCompanyCDNSettings(dispatch, orgId, body, token);
  };
}

export function clearCyclopsCDNConstantsRequest() {
  return (dispatch) => {
    dispatch(cyclopsCDNConstantsClear());
  };
}

export function initiateCyclopsCDNConstantsRequest(orgId, args) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      favicon: { faviconStatus },
      fetchLoader: { dataLoading },
    } = getState();

    if (!dataLoading) dispatch(setFetchLoaderLoading());

    if (faviconStatus !== 'loading') {
      dispatch(setFaviconLoading());
    }

    await fetchCDNConstants(dispatch, orgId, args, token);
  };
}

export function clearCyclopsConstantsConfigRequest() {
  return (dispatch) => {
    dispatch(cyclopsConstantsConfigClear());
  };
}

export function initiateCyclopsConstantsConfigRequest(args) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      favicon: { faviconStatus },
      fetchLoader: { dataLoading },
    } = getState();

    if (!dataLoading) dispatch(setFetchLoaderLoading());

    if (faviconStatus !== 'loading') {
      dispatch(setFaviconLoading());
    }

    await fetchCyclopsConstantsConfig(dispatch, args, token);
  };
}
