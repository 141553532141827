import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import Case from 'case';
import uniqid from 'uniqid';

import { Box, Text } from 'grommet';

import {
  Seo, AppButton, ProductRowStats, UnderConstruction,
} from '@Components/Control';
import { withProductAuth } from '@Components/Layout';
import { AuthNavWrapper } from '@Components/Navigation';
import { ReviewTablePartial } from '@Components/Partial/Product/Dashboard';
import {
  initiateProductDashboardReviewRequest,
  clearProductDashboardReviewRequest,
  initiateProductDashboardReviewStatsRequest,
  clearProductDashboardReviewStatsRequest,
  initiateProductDashboardReviewMetaRequest,
  clearProductDashboardReviewMetaRequest,
  updateProductDashboardReviewSelectionsRequest,
} from '@Actions';

import {
  FlexibleSelectDropdown, ReviewFilterDropdown, SortDropdown,
} from '../../../Partial/Product/SharedComponents';


const DashboardReviewPage = ({
  small,
  size,
  location,
  loading,
  fetchReviewData,
  clearReviewDashboard,
  fetchReviewStats,
  clearReviewStats,
  fetchReviewMeta,
  clearReviewMeta,
  updateProductReviewSelections,
  reviewStats = null,
  reviewMeta = null,
  reviewData = null,
  authPagesConfig = null,
  customReports = null,
  cyclopsConfig = null,
}) => {
  const [reviewedData, setReviewedData] = React.useState([]);
  const [resultsPerPage, setResultsPerPage] = React.useState(10);
  const [activeReviewTab, setActiveReviewTab] = React.useState(null);
  /* eslint-disable camelcase */
  const [query, setQuery] = React.useState({
    filter: {
      domain: null,
      platforms: null,
    },
    sort: {
      created_at: null,
    },
    filterResetKey: uniqid(),
    sortResetKey: uniqid(),
  });

  React.useEffect(() => {
    fetchReviewData({ limit: resultsPerPage, tab_id: activeReviewTab });
    fetchReviewStats();
    fetchReviewMeta();

    return () => {
      clearReviewDashboard();
      clearReviewStats();
      clearReviewMeta();
    };
  }, []);

  React.useEffect(() => {
    const queryObj = {
      limit: resultsPerPage,
      tab_id: activeReviewTab,
    };

    if (query.filter.domain || query.filter.platforms) {
      queryObj.query_filters = query.filter;
    }

    if (query.sort.created_at) {
      queryObj.query_sorts = query.sort;
    }

    fetchReviewData(queryObj);
  }, [query]);

  const handleResultsPerPage = (val) => {
    setResultsPerPage(val);
    fetchReviewData({ limit: val, tab_id: activeReviewTab });
  };

  const handleActiveTab = (val) => {
    setActiveReviewTab(val);
    fetchReviewData({ limit: resultsPerPage, tab_id: val });
  };

  const handleTabFilters = (key, val, reset = false) => {
    if (reset) {
      setQuery((prev) => ({ sort: { ...prev.sort }, filter: { domain: null, platforms: null } }));
      fetchReviewData({ limit: resultsPerPage, tab_id: activeReviewTab });
      return;
    }

    setQuery((prev) => ({ sort: { ...prev.sort }, filter: { ...prev.filter, [key]: val } }));
  };

  const handleTabSort = (key, val) => {
    if (!key) {
      setQuery((prev) => ({ filter: { ...prev.filter }, sort: { created_at: null } }));
      fetchReviewData({
        limit: resultsPerPage, tab_id: activeReviewTab, query_filters: query.filter,
      });
      return;
    }

    setQuery((prev) => ({ filter: { ...prev.filter }, sort: { ...prev.sort, [key]: val } }));
  };

  const handleResetQuery = () => {
    setQuery({
      filter: {
        domain: null,
        platforms: null,
      },
      sort: {
        created_at: null,
      },
      filterResetKey: uniqid(),
      sortResetKey: uniqid(),
    });
  };

  const initializeReviewedRows = (data) => {
    const reviewDataArr = data.map((dt) => {
      const reviewable = _.find(dt, ((row) => row.decoration === 'editable/dropdown'));
      const id = reviewable?.editableId;
      const api = reviewable?.api;

      return { id, review_status: api };
    });

    const filteredReviewable = _.filter(reviewDataArr, ((dt) => dt.id !== undefined));

    setReviewedData(filteredReviewable);
  };

  const updateRowReviewStatus = (editableId, selectedOption) => {
    const reviewDataArr = [...reviewedData];
    const editSelectionIndex = _.findIndex(reviewDataArr, { id: editableId });

    if (editSelectionIndex !== -1) {
      reviewDataArr[editSelectionIndex].review_status = selectedOption;
      setReviewedData(reviewDataArr);
    }
  };

  const submitDisabled = reviewedData.some(({ review_status }) => review_status === 'pending');

  const sendReviewedResults = () => {
    updateProductReviewSelections({ data: reviewedData }, () => {
      fetchReviewData({ limit: resultsPerPage, tab_id: activeReviewTab });
      fetchReviewStats();
    });
    clearReviewDashboard();
    clearReviewStats();
    setReviewedData([]);
    window.scrollTo(0, 0);
  };

  const tableData = reviewData?.data && reviewData.data[0];
  const reviewDataHeaders = small ? _.filter(tableData?.value?.header, ((dt) => dt.display !== 'Review Status')) : tableData?.value?.header;
  const resultsPerPageOptions = [10, 15, 20, 25];
  const currentTab = reviewMeta?.tabs?.find((tab) => tab.id === activeReviewTab);
  const currentFilters = currentTab?.queryFilters;
  const currentSorts = currentTab?.querySorts;
  const selectedTabDisplay = currentTab?.name || null;
  const withFilters = query.filter.domain || query.filter.platforms;
  const underMaintenance = authPagesConfig && authPagesConfig.disableReviewFeatures;

  const padding = small ? '1.5rem' : '2rem';

  const renderPageContent = () => {
    if (underMaintenance) {
      return (
        <Box flex align="center" justify="center" background="white">
          <UnderConstruction
            imageUrl={authPagesConfig.constructionImage}
            buttonHighlight={authPagesConfig.buttonHighlight}
            header="Under Maintenance"
            message="System currently undergoing maintenance. Please check back later."
          />
        </Box>
      );
    }

    return (
      <Box flex background="#F1F2FC" pad={reviewStats ? '0rem' : { top: padding }}>
        {reviewStats && (
          <Box width="100%" direction="row" justify="end" pad={{ vertical: '0.65rem' }}>
            <ProductRowStats label="Positives:" stat={reviewStats.positives} color={authPagesConfig.primaryText} />
            <ProductRowStats label="Reviewed:" stat={reviewStats.reviewed} color={authPagesConfig.primaryText} />
            <ProductRowStats isLast label="Pending:" stat={reviewStats.pendingForCompany} color={authPagesConfig.primaryText} />
          </Box>
        )}
        <ReviewTablePartial
          loading={loading}
          reviewData={tableData?.value?.rows}
          reviewDataHeaders={reviewDataHeaders}
          initializeReviewedRows={initializeReviewedRows}
          updateRowReviewStatus={updateRowReviewStatus}
          selectedStatuses={reviewedData}
          handleResultsPerPage={(val) => handleResultsPerPage(val)}
          resultsPerPageOptions={resultsPerPageOptions}
          resultsPerPage={resultsPerPage}
          totalResults={reviewStats && reviewStats.pendingForCompany}
          authPagesConfig={authPagesConfig}
          size={size}
          tableControls={reviewMeta?.tabs?.length > 0 && (
            <Box margin={{ bottom: '-0.5rem' }} direction="row" gap="1rem">
              <FlexibleSelectDropdown
                withReset
                buttonWidth="9rem"
                buttonJustify="between"
                flexibleOptions={reviewMeta.tabs}
                selectedOption={selectedTabDisplay}
                handleItemSelect={(val) => handleActiveTab(val)}
                textColor={authPagesConfig.primaryText}
                displayKey="name"
                valueKey="id"
                noSelectionLabel="Select filter group"
              />
              {currentFilters?.length > 0 && (
                <ReviewFilterDropdown
                  key={query.filterResetKey}
                  small={small}
                  textColor={authPagesConfig.primaryText}
                  focusHighlight={authPagesConfig.focusHighlight}
                  buttonHighlight={authPagesConfig.buttonHighlight}
                  selectButtonBg={authPagesConfig.selectButtonBg}
                  selectedFilters={withFilters ? query.filter : null}
                  handleFilter={(uid, value, reset) => (
                    handleTabFilters(uid, value, reset)
                  )}
                  filterOptions={currentFilters}
                  optionNames={currentFilters.map(({ name }) => (
                    { name, formatted: Case.capital(name) }
                  ))}
                />
              )}
              {currentSorts?.options?.length > 0 && (
                <SortDropdown
                  key={query.sortResetKey}
                  textColor={authPagesConfig.primaryText}
                  focusHighlight={authPagesConfig.focusHighlight}
                  selectedSortOption={query.sort.created_at ? { name: 'created_at', direction: query.sort.created_at } : null}
                  handleSort={(sortValue, sortDirection) => handleTabSort(sortValue, sortDirection)}
                  sortOptions={currentSorts?.options}
                  orderOptions={currentSorts?.directions}
                />
              )}
            </Box>
          )}
          showRefresh={query.filter.domain || query.filter.platforms || query.sort.created_at}
          handleRefresh={() => handleResetQuery()}
        />
        {tableData?.value?.rows?.length >= 1 && (
          <Box
            width="100%"
            direction={small ? 'column' : 'row'}
            gap={small ? '1rem' : '2rem'}
            justify="between"
            align="center"
            pad={{ horizontal: padding, vertical: '2rem' }}
          >
            <Box width={small ? '100%' : '65%'}>
              <Text size="1rem" weight={500} color={authPagesConfig.primaryText}>
                We will automatically send a DMCA notice to accounts marked as
                infringements when you&apos;re ready for the next set of results.
              </Text>
            </Box>
            <Box pad={{ right: small ? '0rem' : '4rem' }}>
              <AppButton
                overrideHover
                width="10rem"
                disabled={submitDisabled || (reviewedData.length === 0)}
                onClick={() => sendReviewedResults(reviewedData)}
                level="dynamicLarge"
                color="white"
                bgColor={authPagesConfig.buttonHighlight}
                fontWeight={600}
                label="Review Next Set"
                title={submitDisabled ? 'Please select a status option for all rows' : null}
              />
            </Box>
          </Box>
        )}
      </Box>
    );
  };
  /* eslint-enable camelcase */

  return (
    <AuthNavWrapper
      small={small}
      location={location}
      authPagesConfig={authPagesConfig}
      customReports={customReports}
      cyclopsConfig={cyclopsConfig}
      bannerProps={{
        title: 'Review Tool',
        subTitle: 'Review Domains',
        textColor: 'white',
        bannerChildren: (
          <Box>
            <Text color="white" size="1rem">
              Select the domains you wish to target for piracy takedown notices.
            </Text>
          </Box>
        ),
        bannerChildrenPosition: 'bottom',
      }}
    >
      <Seo />
      {renderPageContent()}
    </AuthNavWrapper>
  );
};

function mapStateToProps(state) {
  return {
    reviewData: state.productDashboard.productReviewData,
    reviewStats: state.productDashboard.productReviewStats,
    reviewMeta: state.productDashboard.productReviewMeta,
    loading: state.fetchLoader.dataLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchReviewData: initiateProductDashboardReviewRequest,
    clearReviewDashboard: clearProductDashboardReviewRequest,
    fetchReviewStats: initiateProductDashboardReviewStatsRequest,
    clearReviewStats: clearProductDashboardReviewStatsRequest,
    fetchReviewMeta: initiateProductDashboardReviewMetaRequest,
    clearReviewMeta: clearProductDashboardReviewMetaRequest,
    updateProductReviewSelections: updateProductDashboardReviewSelectionsRequest,
  }, dispatch);
}

DashboardReviewPage.propTypes = {
  small: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'mediumlarge', 'large']).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  updateProductReviewSelections: PropTypes.func.isRequired,
  fetchReviewData: PropTypes.func.isRequired,
  clearReviewDashboard: PropTypes.func.isRequired,
  fetchReviewStats: PropTypes.func.isRequired,
  clearReviewStats: PropTypes.func.isRequired,
  fetchReviewMeta: PropTypes.func.isRequired,
  clearReviewMeta: PropTypes.func.isRequired,
  reviewData: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      feature: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.string.isRequired,
      value: PropTypes.shape({
        header: PropTypes.arrayOf(PropTypes.shape({
          display: PropTypes.string.isRequired,
        }).isRequired).isRequired,
        rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
          api: PropTypes.string,
          decoration: PropTypes.any,
          display: PropTypes.any.isRequired,
          editable: PropTypes.string,
          editableId: PropTypes.string,
          editableOptions: PropTypes.arrayOf(PropTypes.shape({
            api: PropTypes.string.isRequired,
            display: PropTypes.string.isRequired,
          })),
          unit: PropTypes.string,
        }).isRequired).isRequired).isRequired,
      }).isRequired,
    }).isRequired).isRequired,
  }),
  reviewStats: PropTypes.shape({
    positives: PropTypes.number.isRequired,
    reviewed: PropTypes.number.isRequired,
    pendingForCompany: PropTypes.number.isRequired,
  }),
  reviewMeta: PropTypes.shape({
    tabs: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      queryFilters: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        values: PropTypes.arrayOf(PropTypes.shape({
          key: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        }).isRequired),
      }).isRequired).isRequired,
      querySorts: PropTypes.shape({
        directions: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string.isRequired,
          formatted: PropTypes.string.isRequired,
        }).isRequired),
        options: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string.isRequired,
          formatted: PropTypes.string.isRequired,
        }).isRequired),
      }).isRequired,
    }).isRequired).isRequired,
  }),
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    disableReviewFeatures: PropTypes.bool.isRequired,
  }),
  customReports: PropTypes.objectOf(PropTypes.any),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
};

export default connect(mapStateToProps, mapDispatchToProps)(withProductAuth(DashboardReviewPage));
